import { GetServerSideProps, NextPage } from "next";

import Head from "next/head";
import { useRouter } from "next/router";

import { useState } from "react";

import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Container } from "react-bootstrap";

import Footer from "@components/footer";
import HomePageCities from "@components/homepage_cities";
import JobSearchForm from "@components/job_search_form";

import {
  preserve_query_strings,
  fetchPopularCities,
  loadTranslations,
  capitalize,
  redirectToNewLocation,
} from "@lib/index";

import { JobSearchFormData, Location } from "@entities/location";

/**
 * The homepage, parameterized by a country slug, used to present the list
 * of most popular cities.
 *
 * @param mostPopularCities
 * @param citiesAndStates
 * @returns
 */
const Homepage: NextPage = () => {
  const router = useRouter();
  const { country_slug } = router.query;

  const { t } = useTranslation("homepage");
  loadTranslations("homepage");

  const [countrySlug, setCountrySlug] = useState(country_slug);

  // mostPopularCities is an infinite list of popular locations in the selectedCountrySlug
  const [mostPopularCities, setMostPopularCities] = useState<Location[]>([]);

  // formData of the search. It's updated from the JobSearchForm sub component
  const formData = { search: "" };

  /**
   * when the selectedCountry changes, we query the database to re-get the most popular locations
   * @param selectedCountrySlug: the new country slug
   * @returns
   */
  async function onCountryChanged(selectedCountrySlug: string) {
    if (!selectedCountrySlug) return;

    setCountrySlug(selectedCountrySlug);

    // change the url to be the selected country so that
    // if the user reloads or shares the page, we'll have the same selection.
    // You may notice that we don't use the [country_slug] url part here.
    // We actually do use it in a lower component: CountryAndCitySearch
    const { pathname, query } = router;

    const new_url = preserve_query_strings(`/${selectedCountrySlug}`, query);
    router.push({ pathname, query }, new_url, { shallow: true });

    setMostPopularCities(await fetchPopularCities(selectedCountrySlug));
  }

  /**
   * Callback function called when we validate the search form.
   * In the homepage, we redirect the user to the job list result.
   * @param data
   */
  function onFormSubmit(data: JobSearchFormData) {
    redirectToNewLocation(data.location, encodeURIComponent(data.search), router);
  }

  return (
    <>
      <Head>
        <title>{t("page_title", { country: capitalize(countrySlug as string) })}</title>:
      </Head>
      <Container>
        <Container className="homepage-search-container">
          <JobSearchForm
            formData={formData}
            onFormSubmit={onFormSubmit}
            initialLocation={null}
            onCountryChanged={onCountryChanged}
          />
        </Container>
        <hr />

        <Container id="container-home">
          <HomePageCities citiesData={mostPopularCities} />
        </Container>

        <hr />
        <Footer />
      </Container>
    </>
  );
};

/**
 *
 * @returns
 */
export const getServerSideProps: GetServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ["homepage"])),
  },
});

export default Homepage;
