import Link from "next/link";
import { useRouter } from "next/router";

import { useTranslation } from "next-i18next";
import { Button, Container } from "react-bootstrap";

import Title from "@components/title";

import { loadTranslations } from "@lib/i18n";
import { preserve_query_strings } from "@lib/url";

import { Location } from "@entities/location";

/**
 * Shows a list of cities as buttons. Each can be clicked to be redirected
 * to the search_result page.
 *
 * @params citiesData: a list of locations
 */
const HomePageCities = ({ citiesData }: { citiesData: Location[] }) => {
  const router = useRouter();
  const { t } = useTranslation("homepage_components");
  loadTranslations("homepage_components");

  return (
    <Container>
      <Title translation_key="homepage_components:homepage_cities_title" parent="h1" />
      <div className="homepage-titles" style={{ padding: "0px 30px" }}>
        {t("homepage_cities_sub_title")}
      </div>
      <div className="city-search row">
        {citiesData.map((item, index) => (
          <div className="city-search-row col-6" key={index}>
            <Link href={preserve_query_strings(`/city/${item.slug}`, router.query)} passHref={true}>
              <Button variant="outline-dark" size="lg">
                {item.real_name}
              </Button>
            </Link>
          </div>
        ))}
      </div>
    </Container>
  );
};
export default HomePageCities;
